// extracted by mini-css-extract-plugin
export var bannerContainer = "machinery-page-module--bannerContainer--2de93";
export var bannerImage = "machinery-page-module--bannerImage--da0eb";
export var hasOverflow = "machinery-page-module--hasOverflow--87ad8";
export var reversed = "machinery-page-module--reversed--725e4";
export var sectionContent = "machinery-page-module--sectionContent--5e226";
export var sectionImage = "machinery-page-module--sectionImage--2699b";
export var topSection = "machinery-page-module--topSection--c0476";
export var videoContainer = "machinery-page-module--videoContainer--ae86a";
export var videoFallback = "machinery-page-module--videoFallback--941a1";
export var watchExternally = "machinery-page-module--watchExternally--3c99d";