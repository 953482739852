import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PortableText } from '@portabletext/react';
import InfoBlock from "../components/InfoBlock";
import Footer from '../components/Layout/Footer';
import * as styles from '../styles/pages/machinery-page.module.scss';

export const Head = () => (
  <>
    <title>Machinery | GROWoneAFRICA™</title>
    <meta name="description" content="Explore our innovative GOA Decorticator - revolutionizing hemp processing in South Africa with efficient, sustainable technology." />
  </>
)

const MachineryPage = ({ data }) => {
  const {
    title,
    bannerImage,
    machinerySections
  } = data.sanityMachineryPage;

  return (
    <>
      <main>
        <div className={styles.topSection}>
          {bannerImage && (
            <div className={styles.bannerContainer}>
              <GatsbyImage
                image={bannerImage.asset.gatsbyImageData}
                alt={bannerImage.caption || 'Machinery banner'}
                className={styles.bannerImage}
              />
            </div>
          )}
        </div>

        {machinerySections && machinerySections.map((section, index) => (
          <InfoBlock
            key={index}
            featuredImage={
              section.mediaType === 'image' ? (
                <GatsbyImage 
                  image={section.image.asset.gatsbyImageData} 
                  alt={section.image.caption || section.title} 
                  className={styles.sectionImage}
                />
              ) : section.mediaType === 'videoFile' ? (
                <VideoEmbed 
                  file={section.videoFile} 
                  title={section.title} 
                />
              ) : (
                <VideoEmbed 
                  url={section.videoUrl} 
                  title={section.title} 
                />
              )
            }
            reversed={section.position === 'right'}
          >
            <h2>{section.title}</h2>
            <div className={`${styles.sectionContent} ${section._rawText?.length > 500 ? styles.hasOverflow : ''}`}>
              <PortableText value={section._rawText} />
            </div>
          </InfoBlock>
        ))}

        <div style={{ textAlign: 'center', margin: '2rem 0' }}>
          <p style={{ textAlign: 'center' }}>
            The GOA Industrial Dacha Decorticator—locally designed and built in collaboration with Incodev—brings cutting-edge technology to South Africa's hemp industry. Together, we're
            building a green economy and unlocking new possibilities for sustainable development!
          </p>
          <p style={{ textAlign: 'center' }}>
            Want to see the GOA Decorticator in action or learn how it can benefit your community or business? Reach out to us for more information, specifications, or to schedule a demonstration.
          </p>
        </div>
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query MachineryPageQuery {
    sanityMachineryPage {
      title
      bannerImage {
        asset {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      machinerySections {
        title
        _rawText
        position
        mediaType
        image {
          asset {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        videoUrl
        videoFile {
          asset {
            url
            mimeType
          }
        }
      }
    }
  }
`

export default MachineryPage;

const VideoEmbed = ({ url, file, title }) => {
  const [embedError, setEmbedError] = useState(false);
  const [isBrowser, setIsBrowser] = useState(false);
  
  // Set isBrowser to true once component mounts
  useEffect(() => {
    setIsBrowser(true);
  }, []);
  
  const getVideoEmbedUrl = (url) => {
    if (!url) return '';
    
    // Handle various video platform URLs
    const platforms = {
      youtube: {
        regex: /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&?\s]+)/,
        transform: (id) => {
          const origin = isBrowser ? window.location.origin : '';
          return `https://www.youtube-nocookie.com/embed/${id}?enablejsapi=1&origin=${origin}&modestbranding=1&rel=0&widgetid=1`;
        }
      },
      vimeo: {
        regex: /(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^/]*\/videos\/|album\/\d+\/video\/|video\/|))(\d+)(?:[a-zA-Z0-9_-]+)?/,
        transform: (id) => `https://player.vimeo.com/video/${id}`
      },
    };

    for (const [platform, { regex, transform }] of Object.entries(platforms)) {
      const match = url.match(regex);
      if (match && match[1]) {
        return transform(match[1]);
      }
    }

    // If no matching platform is found, check if it's a direct video URL
    const videoExtensions = /\.(mp4|webm|ogg)$/i;
    if (videoExtensions.test(url)) {
      return url;
    }

    // If not a direct video URL, return as iframe src
    return url;
  };

  // If it's a video file from Sanity
  if (file?.asset) {
    const { url, mimeType } = file.asset;
    return (
      <div className={styles.videoContainer}>
        <video
          controls
          playsInline
          width="100%"
          height="400"
          className={styles.videoPlayer}
        >
          <source src={url} type={mimeType} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  // If it's a video URL
  if (url) {
    if (embedError) {
      return (
        <div className={styles.videoFallback}>
          <p>Video is only available on the original platform.</p>
          <a 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.watchExternally}
          >
            Watch Video
          </a>
        </div>
      );
    }

    // Check if it's a direct video URL
    const videoExtensions = /\.(mp4|webm|ogg)$/i;
    if (videoExtensions.test(url)) {
      return (
        <div className={styles.videoContainer}>
          <video
            controls
            playsInline
            width="100%"
            height="400"
            className={styles.videoPlayer}
          >
            <source src={url} type={`video/${url.split('.').pop()}`} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }

    // Otherwise, use iframe for embedded videos
    return (
      <div className={styles.videoContainer}>
        <iframe
          src={getVideoEmbedUrl(url)}
          title={title}
          width="100%"
          height="400"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          loading="lazy"
          onError={() => setEmbedError(true)}
        />
      </div>
    );
  }

  // If neither file nor URL is provided
  return (
    <div className={styles.videoFallback}>
      <p>No video source provided</p>
    </div>
  );
};